// export const WebUrl = "http://192.168.1.64:3001";
// var Endpoint = "http://192.168.1.64:3001";
// export const Host_Name = "http://192.168.1.64:3001";

export const WebUrl = "https://banglabazar.com";
var Endpoint = "https://api.banglabazar.com";
// var Endpoint = "http://localhost:3001";
export const Host_Name = "https://banglabazar.com";

export default Endpoint;
// "http://18.220.235.3:3001"
// "https://api.banglabazar.com"
//http://192.168.2.119:4000





