import routes from "./routes";
import "./assets/css/sidebar.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/dash-counter-card.css";
import "./assets/css/support-chat.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/invoice.css";
import { Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./Helpers/ProtectedRoute";
import { MODAL_CONTEXT_PROVIDER } from "./Components/Contexts/ModalContext";
import { useState } from "react";
import { useEffect } from "react";
import { CurrentUser } from "./Helpers/Auth";
import { Get_Wishlist_Action } from "./Actions/WishlistActions";
import { useDispatch, useSelector } from "react-redux";
import UpdateUserBrowsingOnLogin from "./Helpers/UpdateUserBrowsingOnLogin";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import BanglaBazarApi from "./Components/Api/BanglaBazarApi";
import Endpoint from "./Utils/Endpoint";
import firetoast from "./Helpers/FireToast";
import {
  getCategoryAndSubCategory,
  getDepartmentCategoryAndSubCategory,
  getCurrentIpv4,
  getLandingPageData,
} from "./Actions/LandingActions";

// import 'antd/dist/antd.css';

function App() {
  const [signin, setSignin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [EmailToBeVerified, setEmailToBeVerified] = useState("");
  const [PhoneNumberToBeVerified, setPhoneNumberToBeVerified] = useState("");
  const [otpRedirectTo, setOtpRedirectTo] = useState(null);
  const dispatch = useDispatch();
  // const location = useLocation()
  const state = useSelector((state) => state);




  useEffect(async () => {




    // dispatch(getDepartmentCategoryAndSubCategory());
    dispatch(getCategoryAndSubCategory());
    dispatch(getCurrentIpv4());


    //if (CurrentUser) dispatch(Get_Wishlist_Action());
    var isUser = localStorage.getItem("user");
    if (state.currentIPv4.IP["country_name"]) {
      {
        console.log(
          state.currentIPv4.IP["country_name"],
          "state.currentIPv4.IP"
        );
      }
      if (!localStorage.getItem("region")) {
        // localStorage.setItem("region", state.currentIPv4.IP["country_name"]);
        localStorage.setItem("region", 'United States');
        window.location.reload();
      }
      if (isUser) {
        dispatch(
          getLandingPageData(
            state.currentIPv4.IP["country_name"],
            CurrentUser.UserID
          )
        );
        UpdateUserBrowsingOnLogin(state.currentIPv4);
      } else {
        if (
          getLandingPageData(
            state.currentIPv4.IP["country_name"] === "Bangladesh"
          )
        )
          dispatch(
            getLandingPageData(state.currentIPv4.IP["country_name"], "")
          );
        else
          dispatch(
            getLandingPageData(state.currentIPv4.IP["country_name"], "1")
          );
      }
    }
    CurrentUser && updateCartItems();
  }, [dispatch, state.currentIPv4.IP["country_name"]]);
  let updateCartItems = async () => {
    var localCart = JSON.parse(localStorage.getItem("uLC"));
    var ProductDetail = [];
    if (localCart && localCart.length > 0) {
      // console.log(localCart);
      for (let i = 0; i < localCart.length; i++) {
        ProductDetail.push(JSON.parse(localCart[i]).ProductDetail[0]);
      }
      var data = {
        SessionID: localStorage.getItem("accessToken"),
        ProductDetail,
      };

      try {
        var response = await BanglaBazarApi.post(
          `${Endpoint}/api/wish-list/addCart`,
          data
        );
        if (response.data.status) {
          setTimeout(() => localStorage.removeItem("uLC"), 2000);
        } else {
          var { message, error } = response.data;
          firetoast(message || error, "default-error");
        }
      } catch (e) {
        firetoast("Something went wrong while updating cart", "default-error");
      }
    }
  };
  return (
    <MODAL_CONTEXT_PROVIDER
      value={{
        signin,
        setSignin,
        signup,
        setSignup,
        phoneVerify,
        setPhoneVerify,
        emailVerify,
        setEmailVerify,
        EmailToBeVerified,
        setEmailToBeVerified,
        PhoneNumberToBeVerified,
        setPhoneNumberToBeVerified,
        otpRedirectTo,
        setOtpRedirectTo,
      }}
    >
      <div className="App">
        {routes.map((route, index) => (
          <div key={index}>
            {route.protect ? (
              <ProtectedRoute
                path={route.path}
                component={route.component}
                key={index}
                exact={route.exact}
              // onUpdate={() => window.scrollTo(0, 0)}
              />
            ) : (
              <Route
                path={route.path}
                component={route.component}
                key={index}
                exact={route.exact}
              // onUpdate={() => window.scrollTo(0, 0)}
              />
            )}
          </div>
        ))}
      </div>
    </MODAL_CONTEXT_PROVIDER>
  );
}
export default App;
