import { useContext, useState, useEffect } from "react";
import { PRODUCT_FORM_CONTEXT } from "./../../../../Contexts/ProductFormContext";
import { Link, useParams } from "react-router-dom";
import firetoast from "./../../../../../Helpers/FireToast";
import Resizer from "react-image-file-resizer";
import BanglaBazarApi from "./../../../../Api/BanglaBazarApi";
import Endpoint from "./../../../../../Utils/Endpoint";
import CheckEmpty from "./../../../../../Utils/CheckEmpty";
import { CurrentUser } from "./../../../../../Helpers/Auth";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Alert,
} from "reactstrap";
import { RequiredField } from "../../../../../Utils/Required-field";

function EditProductVariants({ Active, ReviewedByAdmin }) {
  var [VariationValues, setVariationValues] = useState([]);
  var [optionid, setOptionid] = useState(null);
  var [showModal, setshowModal] = useState(false);
  var [AvailabaleValues, setAvailabaleValues] = useState([]);
  var [isAddCustom, setIsAddCustom] = useState(false);
  var [ModalSmall, setModalSmall] = useState({});
  var [ModalMedium, setModalMedium] = useState({});
  var [ModalLarge, setModalLarge] = useState({});
  var [ModalButton, setModalButton] = useState(false);
  var [ModalPrice, setModalPrice] = useState("0");
  var [disableBtn, setDisableBtn] = useState(false);
  const [editeAble, setEditeABle] = useState(false);


  var [smallImage, setSmallImage] = useState(null)
  var [meduimImage, setMeduimImage] = useState(null)
  var [LargeImage, setLargeImage] = useState(null)


  var { productID } = useParams();
  const {
    Options,
    setOptions,
    SubCategoryVariants,
    SKU,
    setSKU,
    Price,
    Status,
    ProductID,
    StoreName,
    setPrice,
    AvailableInventory,
    OptionId,
    setOptionId,
    setAvailableInventory,
    setSmall,
    setMedium,
    setLarge,
    OptionValue,
    setOptionValue,
    MainImage,
    Inventory,
    setInventory,
    UnitPrice,
    setUnitPrice,
    TotalPrice,
    setTotalPrice,
    Small,
    Medium,
    Large,
    Variations,
    getOptions,
    Currency,
    AvailableVariations,
  } = useContext(PRODUCT_FORM_CONTEXT);

  useEffect(async () => {
    await getAllVariantAndValues();
    ;
  }, []);



  var getAllVariantAndValues = async () => {

    try {
      var response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productAllDetails/${productID}/${CurrentUser.UserID}`
      );

      const res = response.data.Product
      setVariationValues(res);


      // setVariationValues(data)

      console.log(VariationValues);

    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while getting varaint and values",
        "default-error"
      );
    }
  };
  var UpdateVarantValueImage = async (file) => {
    if (file) {
      // console.log(await VariantImageResizer(file,1,1))

      await Resizer.imageFileResizer(
        file,
        50,
        50,
        "PNG",
        100,
        0,
        (uri) => {
          setSmall(uri);
        },
        "file"
      );
      await Resizer.imageFileResizer(
        file,
        200,
        200,
        "PNG",
        100,
        0,
        (uri) => {
          setMedium(uri);
        },
        "file"
      );
      await Resizer.imageFileResizer(
        file,
        400,
        550,
        "PNG",
        100,
        0,
        (uri) => {
          setLarge(uri);
        },
        "file"
      );
    }
  };


  useEffect(() => {
    if (Active === 'N' && ReviewedByAdmin === "N") {
      setEditeABle(true)
    }
  }, [Active, ReviewedByAdmin])




  //! there is the value


  var submitOptionValueDetail = async (id, item) => {
    var {
      VariantValue,
      SKU,
      Price,
      AvailableInventory,
      Inventory,
      UnitPrice,
      TotalPrice,
      Small,
      Medium,
      Large,
      MainImage,
    } = item;
    if (CheckEmpty(VariantValue)) {
      return firetoast("Option Value Name is required", "default-error");
    }
    if (CheckEmpty(SKU)) {
      return firetoast("SKU is required", "default-error");
    }
    if (CheckEmpty(Price)) {
      return firetoast("Price is required", "default-error");
    }
    if (CheckEmpty(AvailableInventory)) {
      return firetoast("Available Inventory is required", "default-error");
    }
    if (CheckEmpty(Inventory)) {
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(AvailableInventory) > parseInt(Inventory)) {
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      return firetoast("Total Price is required", "default-error");
    }
    if (MainImage === "Y") {
      var found = false;

      for (var i = 0; i < VariationValues.length; i++) {
        if (
          VariationValues[i].VariantValue !== VariantValue &&
          VariationValues[i].MainImage === "Y"
        ) {
          found = true;
        }
      }
      if (found) {
        return firetoast(
          "Only one Main Image can be set from the available variant values",
          "error",
          3000,
          "top-right"
        );
      }
    }
    try {
      var form = new FormData();
      form.append("ProductID", productID);
      form.append("StoreName", StoreName);
      form.append("OptionID", optionid);
      form.append("OptionValue", VariantValue);
      form.append("MainImage", MainImage);
      form.append("SKU", SKU);
      form.append("Price", Price);
      form.append("AvailableInventory", AvailableInventory);
      form.append("Inventory", Inventory);
      form.append("UnitPrice", UnitPrice);
      form.append("TotalPrice", TotalPrice);
      form.append("Status", Status);
      form.append("Small", Small);
      form.append("Medium", Medium);
      form.append("Large", Large);

      var response = await BanglaBazarApi.put(
        `${Endpoint}/api/product/updateProduct-Form2/${id}`,
        form
      );
      getAllVariantAndValues()
      // console.log("data==============>", data);




      console.log(response);
      if (response.data.status) {
        firetoast(
          "Option Value updated Added Successfully",
          "success",
          3000,
          "top-right"
        );
        // getAllVariantAndValues();
      } else {
        var { message, error } = response.data;
        return firetoast(message || error, "default-error");
      }


    } catch (e) {
      console.log(e);
      return firetoast(
        "Something went wrong while updating option value deatils",
        "default-error"
      );
    }
  };
  var AddNewOptionValue = async (item) => {
    var {
      VariantValue,
      SKU,
      Price,
      AvailableInventory,
      Inventory,
      UnitPrice,
      TotalPrice,
      Small,
      Medium,
      Large,
    } = item;


    if (CheckEmpty(VariantValue)) {
      return firetoast("Option Value Name is required", "default-error");
    }
    if (CheckEmpty(SKU)) {
      return firetoast("SKU is required", "default-error");
    }
    if (CheckEmpty(Price)) {
      return firetoast("Price is required", "default-error");
    }
    if (CheckEmpty(AvailableInventory)) {
      return firetoast("Available Inventory is required", "default-error");
    }

    if (CheckEmpty(Inventory)) {
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(AvailableInventory) > parseInt(Inventory)) {
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      return firetoast("Total Price is required", "default-error");
    }
    if (MainImage === "Y") {
      var found = 0;

      for (var i = 0; i < VariationValues.length; i++) {
        if (
          VariationValues[i].VariantValue !== VariantValue &&
          VariationValues[i].MainImage === "Y"
        ) {
          found += 1;
        }
      }
      if (VariationValues[0].MainImage === "Y") {
        return firetoast(
          "Only one Main Image can be set from the available variant values",
          "error",
          3000,
          "top-right"
        );
      }
    }
    try {
      var form = new FormData();
      form.append("ProductID", productID);
      form.append("StoreName", StoreName);
      form.append("OptionID", optionid);
      form.append("OptionValue", VariantValue);
      form.append("MainImage", MainImage);
      form.append("SKU", SKU);
      form.append("Price", Price);
      form.append("AvailableInventory", AvailableInventory);
      form.append("Inventory", Inventory);
      form.append("UnitPrice", UnitPrice);
      form.append("TotalPrice", TotalPrice);
      form.append("Status", Status);
      form.append("Small", Small);
      form.append("Medium", Medium);
      form.append("Large", Large);

      var response = await BanglaBazarApi.post(
        `${Endpoint}/api/product/addProduct-Form2`,
        form
      );
      if (response.data.status) {
        firetoast(
          "Option Value Details Added Successfully",
          "success",
          3000,
          "top-right"
        );
        getAllVariantAndValues();
        clearDetails();
      } else {
        var { message, error } = response.data;
        return firetoast(message || error, "default-error");
      }
    } catch (e) {
      console.log(e);
      return firetoast(
        "Something went wrong while adding option value deatils",
        "default-error"
      );
    }
  };
  var clearDetails = () => {
    setOptionId("null");
    setOptionValue("");
    setSKU("");
    setPrice("");
    setInventory("");
    setTotalPrice("");
    setAvailableInventory("");
    setSmall("");
    setMedium("");
    setLarge("");
  };
  let getOptionValues = async (id) => {
    try {
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/product/get-productVariantDetail/${id}`
      );
      var data = response.data.ProductVariants;
      for (let i = 0; i < data.length; i++) {
        data[i].isNew = false;
        data[i].UnitPrice = 0
        data[i].TotalPrice = 0
      }
      setVariationValues(data);
    } catch (e) {
      firetoast(
        "Something went wrong while fething option values",
        "default-error"
      );
    }
  };


  let handleVariationValueChange = (e, index) => {
    var array = [...VariationValues];
    console.log(array);

    array[index][e.target.name] = e.target.value;
    if (e.target.name === 'VariantPrice') {
      array[index]['Price'] = e.target.value;
    }
    console.log("PRICE =======>", Price);
    setVariationValues(array);


  };
  let handleVariationValueMainImageChange = (e, index) => {
    var array = [...VariationValues];
    array[index].MainImage = e;
    setVariationValues(array);
  };
  let setVariationList = async () => {
    let foundVariationLabel = "";
    for (let i = 0; i < Variations.length; i++) {
      if (parseInt(Variations[i].value) === parseInt(optionid)) {
        console.log("inside");
        foundVariationLabel = Variations[i].label;
      }
    }
    let foundVariationId = "";
    for (let i = 0; i < AvailableVariations.length; i++) {
      if (AvailableVariations[i].label === foundVariationLabel) {
        foundVariationId = AvailableVariations[i].id;
      }
    }
    console.log(foundVariationLabel, "foundVariationLabel");
    console.log(AvailableVariations, "AvailableVariations");
    console.log(optionid, "optionid");
    console.log(foundVariationId, "foundVariationId");
    try {
      var response = await BanglaBazarApi.get(
        `${Endpoint}/api/category/get-subcategoryvariantvalue/${foundVariationId}`
      );
      setAvailabaleValues(response.data.SubCategoryVariantValue);
    } catch (e) {
      firetoast("Something went wrong!", "default-error");
    }
  };
  var handleUpdateVarantValueImage = async (file, index) => {
    var array = [...VariationValues];
    if (file) {
      // console.log(await VariantImageResizer(file,1,1))

      await Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          array[index].Small = uri;
        },
        "file",
        1,
        1
      );
      await Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          array[index].Medium = uri;
        },
        "file",
        10,
        10
      );
      await Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          array[index].Large = uri;
        },
        "file",
        100,
        100
      );
      setVariationValues(array);
    }
  };
  var AddNewOption = async () => {
    var array = [...VariationValues];
    if (optionid != null) {
      array.push({
        ProductID: "",
        StoreName: "",
        OptionID: "",
        OptionValue: "",
        MainImage: "N",
        SKU: "",
        Price: "",
        AvailableInventory: "",
        Inventory: "",
        UnitPrice: "",
        TotalPrice: "",
        Status: "",
        Small: "",
        Medium: "",
        Large: "",
        isNew: true,
      });
      setVariationValues(array);
    }
  };
  var removeVariantValue = async (id) => {
    try {
      const response = await BanglaBazarApi.delete(
        `${Endpoint}/api/product/delete-productVariantValue/${id}`
      );
      if (response.data.status) {
        firetoast(
          "Variant Value removed successfully",
          "success",
          3000,
          "top-right"
        );
        getOptionValues(optionid);
      }
    } catch (e) {
      firetoast(
        "Something went wrong while removing variant value",
        "default-error"
      );
    }
  };
  // var CreateNewValue = async () => {
  //   let VariantValue = document.getElementById("valueName").value;
  //   let SKU = document.getElementById("variantSKU").value;
  //   let Price = document.getElementById("variantPrice").value;
  //   let Inventory = document.getElementById("variantInventory").value;
  //   let AvailableInventory = document.getElementById(
  //     "variantAvailableInventory"
  //   ).value;
  //   let TotalPrice = document.getElementById("variantTotalPrice").value;
  //   let UnitPrice = document.getElementById("variantUnitPrice").value;
  //   let file = document.getElementById("variantImage").files[0];

  //   if (CheckEmpty(VariantValue)) {
  //     return firetoast("Option Value Name is required", "default-error");
  //   }

  //   if (CheckEmpty(SKU)) {
  //     return firetoast("SKU is required", "default-error");
  //   }
  //   if (CheckEmpty(Price)) {
  //     return firetoast("Price is required", "default-error");
  //   }
  //   if (CheckEmpty(AvailableInventory)) {
  //     return firetoast("Available Inventory is required", "default-error");
  //   }

  //   if (CheckEmpty(Inventory)) {
  //     return firetoast("Inventory is required", "default-error");
  //   }
  //   if (parseInt(Inventory) < parseInt(AvailableInventory)) {
  //     return firetoast(
  //       "Available Inventory should be less than Inventory",
  //       "default-error"
  //     );
  //   }
  //   if (CheckEmpty(UnitPrice)) {
  //     return firetoast("Unit Price is required", "default-error");
  //   }
  //   if (CheckEmpty(TotalPrice)) {
  //     return firetoast("Total Price is required", "default-error");
  //   }
  //   if (!file) {
  //     return firetoast("Image is required", "defaulr-error");
  //   }
  //   if (file) {
  //     // console.log(await VariantImageResizer(file,1,1))

  //     Resizer.imageFileResizer(
  //       file,
  //       300,
  //       300,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         console.log(uri);
  //         setModalSmall(uri);
  //       },
  //       "file",
  //       1,
  //       1
  //     );
  //     Resizer.imageFileResizer(
  //       file,
  //       300,
  //       300,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         console.log(uri);
  //         setModalMedium(uri);
  //       },
  //       "file",
  //       10,
  //       10
  //     );
  //     Resizer.imageFileResizer(
  //       file,
  //       300,
  //       300,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         setModalLarge(uri);
  //         console.log(uri);
  //       },
  //       "file",
  //       100,
  //       100
  //     );
  //   }
  //   // if (MainImage === "Y") {
  //   //   var found = 0;

  //   //   for (var i = 0; i < VariationValues.length; i++) {
  //   //     if (
  //   //       VariationValues[i].VariantValue !== VariantValue &&
  //   //       VariationValues[i].MainImage === "Y"
  //   //     ) {
  //   //       found += 1;
  //   //     }
  //   //   }
  //   //   if (VariationValues[0].MainImage === "Y") {
  //   //     return firetoast(
  //   //       "Only one Main Image can be set from the available variant values",
  //   //       "error",
  //   //       3000,
  //   //       "top-right"
  //   //     );
  //   //   }
  //   // }
  //   for (let i = 0; i < VariationValues.length; i++) {
  //     if (VariationValues[i].VariantValue === VariantValue) {
  //       setModalButton(true);
  //       return firetoast("Value already exist with this name", "default-error");
  //     }
  //   }
  //   try {
  //     var form = new FormData();
  //     form.append("ProductID", productID);
  //     form.append("StoreName", StoreName);
  //     form.append("OptionID", optionid);
  //     form.append("OptionValue", VariantValue);
  //     form.append("MainImage", "N");
  //     form.append("SKU", SKU);
  //     form.append("Price", Price);
  //     form.append("AvailableInventory", AvailableInventory);
  //     form.append("Inventory", Inventory);
  //     form.append("UnitPrice", UnitPrice);
  //     form.append("TotalPrice", TotalPrice);
  //     form.append("Status", "Y");

  //     form.append("Small", ModalSmall);
  //     form.append("Medium", ModalMedium);
  //     form.append("Large", ModalLarge);

  //     console.log("IMAGES DATA===============>", ModalSmall, ModalMedium, ModalLarge, TotalPrice);
  //     // return


  //     var response = await BanglaBazarApi.post(
  //       `http://localhost:3001/api/product/addProduct-Form2`,
  //       form
  //     );
  //     console.log(response);
  //     if (response.data.status) {
  //       firetoast(
  //         "Option Value Details Added Successfully",
  //         "success",
  //         3000,
  //         "top-right"
  //       );
  //       getAllVariantAndValues();
  //       setshowModal(false);
  //       clearDetails();
  //     } else {
  //       var { message, error } = response.data;
  //       return firetoast(message || error, "default-error");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     return firetoast(
  //       "Something went wrong while adding option value deatils",
  //       "default-error"
  //     );
  //   }
  // };




  var CreateNewValue = async () => {
    setDisableBtn(true)

    let VariantValue = document.getElementById("valueName").value;
    let SKU = document.getElementById("variantSKU").value;
    let Price = document.getElementById("variantPrice").value;
    let Inventory = document.getElementById("variantInventory").value;
    let AvailableInventory = document.getElementById(
      "variantAvailableInventory"
    ).value;
    let TotalPrice = document.getElementById("variantTotalPrice").value;
    let UnitPrice = document.getElementById("variantUnitPrice").value;
    let file = document.getElementById("variantImage").files[0];

    if (CheckEmpty(VariantValue)) {
      setDisableBtn(false)
      return firetoast("Option Value Name is required", "default-error");
    }

    if (CheckEmpty(SKU)) {
      setDisableBtn(false)
      return firetoast("SKU is required", "default-error");
    }
    if (CheckEmpty(Price)) {
      setDisableBtn(false)
      return firetoast("Price is required", "default-error");
    }
    if (CheckEmpty(AvailableInventory)) {
      setDisableBtn(false)
      return firetoast("Available Inventory is required", "default-error");
    }

    if (CheckEmpty(Inventory)) {
      setDisableBtn(false)
      return firetoast("Inventory is required", "default-error");
    }
    if (parseInt(Inventory) < parseInt(AvailableInventory)) {
      setDisableBtn(false)
      return firetoast(
        "Available Inventory should be less than Inventory",
        "default-error"
      );
    }
    if (CheckEmpty(UnitPrice)) {
      setDisableBtn(false)
      return firetoast("Unit Price is required", "default-error");
    }
    if (CheckEmpty(TotalPrice)) {
      setDisableBtn(false)
      return firetoast("Total Price is required", "default-error");
    }
    if (!file) {
      setDisableBtn(false)
      return firetoast("Image is required", "defaulr-error");
    }

    if (file) {
      try {
        const resizePromises = [];

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                setModalSmall(uri);
                resolve(uri);
              },
              "file",
              1,
              1
            );
          })
        );

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                setModalMedium(uri);
                resolve(uri);
              },
              "file",
              10,
              10
            );
          })
        );

        resizePromises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                setModalLarge(uri);
                console.log(uri);
                resolve(uri);
              },
              "file",
              100,
              100
            );
          })
        );


        const [smallUri, mediumUri, largeUri] = await Promise.all(resizePromises);


        setSmallImage(smallUri)
        setMedium(mediumUri)
        setLargeImage(largeUri)

        for (let i = 0; i < VariationValues.length; i++) {
          if (VariationValues[i].VariantValue === VariantValue) {
            setModalButton(true);
            setDisableBtn(false)
            return firetoast("Value already exists with this name", "default-error");
          }
        }

        try {
          var form = new FormData();
          form.append("ProductID", productID);
          form.append("StoreName", StoreName);
          form.append("OptionID", optionid);
          form.append("OptionValue", VariantValue);
          form.append("MainImage", "N");
          form.append("SKU", SKU);
          form.append("Price", Price);
          form.append("AvailableInventory", AvailableInventory);
          form.append("Inventory", Inventory);
          form.append("UnitPrice", UnitPrice);
          form.append("TotalPrice", TotalPrice);
          form.append("Status", "Y");

          form.append("Small", smallUri);
          form.append("Medium", mediumUri);
          form.append("Large", largeUri);


          var response = await BanglaBazarApi.post(
            `${Endpoint}/api/product/addProduct-Form2`,
            form
          );
          console.log(response);
          if (response.data.status) {
            firetoast(
              "Option Value Details Added Successfully",
              "success",
              3000,
              "top-right"
            );
            getAllVariantAndValues();
            setDisableBtn(false)
            setshowModal(false);
            clearDetails();
          } else {
            var { message, error } = response.data;
            return firetoast(message || error, "default-error");
          }
        } catch (e) {
          setDisableBtn(false);
          return firetoast(
            "Something went wrong while adding option value details",
            "default-error"
          );
        }

      } catch (error) {
        setDisableBtn(false);
        console.log(error);
        return firetoast(
          "Something went wrong while resizing the image",
          "default-error"
        );
      }
    }


  };



  return (
    <>
      <div>
        <b style={{ fontWeight: "600", fontSize: "15px" }}>
          Select the availabe variants from the list{" "}
        </b>
      </div>
      <div className="row">
        <div className="col-6">
          <select
            className="form-control"
            onChange={(e) => {
              console.log(e.target.value);
              getOptionValues(e.target.value);
              setOptionid(e.target.value);
              console.log(e.target.value == 'null');
              if (e.target.value == 'null') {
                getAllVariantAndValues()
              }
              // getOptionsValues(e.target.value);
            }}
          >
            <option value={"null"} >Select All</option>
            {Variations.map((item, index) => (
              <option
                value={item.value}
                key={index}
                selected={OptionId === item.value}
              >
                {item.label}
              </option>
            ))}
          </select>
        </div>

        {optionid !== null && optionid !== "null" && (
          <div className="col-6">
            <div style={{ float: "left" }}>
              <button
                disabled={editeAble}

                className="btn btn-success"
                // onClick={() => AddNewOption()}
                onClick={() => {
                  setshowModal(!showModal);
                  setVariationList();
                }}
              >
                Add New Variant Value
              </button>
            </div>
          </div>
        )}
      </div>
      {VariationValues.map((item, index) => {
        return <div

          className="row p-3 m-3"
          style={{ border: "1px solid rgba(128, 128, 128, 0.58) " }}
          key={index}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="ftw-400 text-default">
              {item.VariantName} - {item.VariantValue}
            </h5>
            {!item.isNew && VariationValues.length > 1 && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => removeVariantValue(item.OptionValueID)}
              >
                <i className="fas fa-times text-danger"></i>
              </div>
            )}
          </div>

          <div className="col-6">
            <label>Value <RequiredField /></label>
            <input
              disabled={editeAble}

              className="form-control"
              type="text"
              name="VariantValue"
              onChange={(e) => handleVariationValueChange(e, index)}
              value={item.VariantValue}
            />
          </div>
          <div className="col-6">
            <label>SKU <RequiredField /></label>
            <input
              disabled={editeAble}

              className="form-control"
              type="text"
              value={item.SKU}
              name="SKU"
              onChange={(e) => handleVariationValueChange(e, index)}
            />
          </div>
          <div className="col-6">
            <label>Price <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              // value={item.Price}
              value={item.VariantPrice}
              onChange={(e) =>
                handleVariationValueChange(e, index)
              }
              name="VariantPrice"
            />

            <Alert color="success" className="p-1 m-1">
              Total Price ={" "}

              {`${Currency}  ${parseFloat(Price ? Price : 0) +
                parseFloat(item.VariantPrice ? item.VariantPrice : 0)
                } ,this amount is sum of base price of this product and current variation value price`}{" "}
            </Alert>
          </div>
          <div className="col-6">
            <label>Inventory <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              value={item.Inventory}
              onChange={(e) => handleVariationValueChange(e, index)}
              name="Inventory"
            />
          </div>
          <div className="col-6">
            <label>Available Inventory <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              onChange={(e) => handleVariationValueChange(e, index)}
              name="AvailableInventory"
              value={item.AvailableInventory}
            />
          </div>
          <div className="col-6">
            <label>Total Price <RequiredField /></label>
            <input
              className="form-control"
              type="number"
              disabled={editeAble}

              // value="0"
              onChange={(e) => handleVariationValueChange(e, index)}
              name="TotalPrice"
              value={item.TotalPrice}
            />
          </div>
          <div className="col-6">
            <label>Unit Price <RequiredField /></label>
            <input
              disabled={editeAble}

              className="form-control"
              type="number"
              onChange={(e) => handleVariationValueChange(e, index)}
              name="UnitPrice"
              value={item.UnitPrice}
            />
          </div>

          <div className="col-6">
            <div className="row ">
              <div className="col-6">
                <label>Image <RequiredField /></label>
                <input
                  disabled={editeAble}

                  className="form-control"
                  type="file"
                  onChange={(e) =>
                    handleUpdateVarantValueImage(e.target.files[0], index)
                  }
                />

                <div className="col-6">
                  {!item.isNew && (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          disabled={editeAble}

                          className="form-check-input default-check-color"
                          type="checkbox"
                          id="inlineCheckbox3"
                          defaultChecked={item.MainImage === "Y"}
                          onChange={() =>
                            handleVariationValueMainImageChange(
                              item.MainImage === "Y" ? "N" : "Y",
                              index
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          for="inlineCheckbox3"
                        >
                          Main Image
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-6 mt-3">
                <img
                  disabled={editeAble}
                  src={`${Endpoint}/${item.Medium}`} className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="w-100">
            {item.isNew ? (
              <div style={{ float: "right" }}>
                <button
                  disabled={editeAble}

                  className="btn btn-success"
                  onClick={() => AddNewOptionValue(item)}
                // disabled={disableBtn}

                >
                  Add Value{" "}
                </button>
              </div>
            ) : (
              <div style={{ float: "right" }} className="mt-3">
                <button
                  disabled={editeAble}

                  className="btn btn-success"
                  onClick={(e) =>
                    submitOptionValueDetail(item.OptionValueID, item)
                  }
                >
                  Save{" "}
                </button>
              </div>
            )}
          </div>
        </div>
      })}
      <Modal
        toggle={() => setshowModal(!showModal)}
        isOpen={showModal}
        size="lg"
      >
        <ModalHeader toggle={() => setshowModal(!showModal)}>
          Custom Value
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-6">
              <label>Value <RequiredField /></label>
              {isAddCustom ? (
                <input
                  disabled={editeAble}

                  className="form-control"
                  type="text"
                  name="VariantValue"
                  id="valueName"
                  onChange={() => setModalButton(false)}
                />
              ) : (
                <select
                  disabled={editeAble}

                  className="form-control"
                  type="text"
                  name="VariantValue"
                  id="valueName"
                  onChange={() => setModalButton(false)}
                >
                  <option value={""}>Select Available Option</option>
                  {AvailabaleValues.map((item, index) => (
                    <option value={item.VariantValue}>
                      {item.VariantValue}
                    </option>
                  ))}
                </select>
              )}
              {isAddCustom ? (
                <Link
                  to="#"
                  className="td-none text-danger"
                  onClick={() => {
                    setIsAddCustom(false);
                    document.getElementById("valueName").value = "";
                  }}
                >
                  Cancel
                </Link>
              ) : (
                <Link
                  to="#"
                  className="td-none text-default"
                  onClick={() => {
                    setIsAddCustom(true);
                    document.getElementById("valueName").value = "";
                  }}
                >
                  Add Custom
                </Link>
              )}
            </div>
            <div className="col-6">
              <label>SKU <RequiredField /></label>
              <input className="form-control" type="text" id="variantSKU" />
            </div>
            <div className="col-6">
              <label>Price <RequiredField /></label>
              <input
                disabled={editeAble}

                className="form-control"
                type="number"
                defaultValue={0}
                id="variantPrice"
                onChange={(e) => setModalPrice(e.target.value)}
              />
              <Alert color="success" className="p-1 m-1">
                Total Price ={" "}
                {`${Currency}  ${parseFloat(Price ? Price : 0) +
                  parseInt(ModalPrice ? ModalPrice : 0)
                  } ,this amount is sum of base price of this product and current variation value price`}{" "}
              </Alert>
            </div>
            <div className="col-6">
              <label>Inventory <RequiredField /></label>
              <input
                disabled={editeAble}

                className="form-control"
                type="number"
                min={0}
                id="variantInventory"
              />
            </div>
            <div className="col-6">
              <label>Available Inventory <RequiredField /></label>
              <input
                disabled={editeAble}

                className="form-control"
                type="number"
                min={0}
                id="variantAvailableInventory"
              />
            </div>
            <div className="col-6">
              <label>Total Price <RequiredField /></label>
              <input
                disabled={editeAble}

                className="form-control"
                type="number"
                defaultValue={0}
                min={0}
                id="variantTotalPrice"
              />
            </div>
            <div className="col-6">
              <label>Unit Price <RequiredField /></label>
              <input
                disabled={editeAble}

                className="form-control"
                type="number"
                defaultValue={0}
                min={0}
                id="variantUnitPrice"
              />
            </div>

            <div className="col-6">
              <label>Image <RequiredField /></label>
              <input
                disabled={editeAble}

                className="form-control" type="file" id="variantImage"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => CreateNewValue()}
            // disabled={ModalButton}
            disabled={disableBtn}
          >

            {
              disableBtn ? <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div> : <span>Add Value</span>
            }
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
export default EditProductVariants;
