import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import firetoast from "../../../../Helpers/FireToast";
import Endpoint from "../../../../Utils/Endpoint";
import BanglaBazarApi from "../../../Api/BanglaBazarApi";
import UserInvoiceForm from "../../Admin panel/Pages/Order Management/UserInvoiceForm";
import AdminInvoiceForm from "../../Admin panel/Pages/Order Management/AdminInvoiceForm";

export function UserOrderInvoicePrint() {
  const history = useHistory();
  const [OrderDetails, setOrderDetails] = useState(null);
  const [OrderDeliveryDetails, setOrderDeliveryDetails] = useState(null);
  const { orderNumber, type, orderStatus } = useParams();

  useEffect(() => {
    getOrderDetails();
    // console.log("-------------------------------->", OrderDeliveryDetails);
    console.log(orderStatus);
  }, []);



  var getOrderDetails = async () => {
    try {
      const response = await BanglaBazarApi.get(
        `${Endpoint}/api/admin/getOrder/${orderNumber}`
      );

      setOrderDetails(response.data.orderDetails);
      setOrderDeliveryDetails(response.data.orderShippingDetail);

      // console.log("___________________________________-", OrderDeliveryDetails, OrderDetails);

    } catch (e) {
      firetoast(
        "Something went wrong while fetching order details",
        "default-error"
      );
    }
  };

  return OrderDetails ? (
    <div className="mt-5">
      <div className="row m-0">
        <div className="col-10 m-auto">
          <div className="card mt-2">
            <div className="card-body">
              <AdminInvoiceForm
                OrderDeliveryDetails={OrderDeliveryDetails}
                OrderDetails={OrderDetails}
                getOrderDetails={getOrderDetails}
                type={"user"}

              />{" "}
              {/* <UserInvoiceForm
                OrderDeliveryDetails={OrderDeliveryDetails}
                OrderDetails={OrderDetails}
                getOrderDetails={getOrderDetails}
                type={"user"}
              />{" "} */}
            </div>{" "}
          </div>{" "}
        </div>
      </div>
    </div>
  ) : null;
}
