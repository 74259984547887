import { useHistory } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import accessdenied from "../../../../assets/images/accessdenied.png";
function GlobalUSAShipping() {
  const history = useHistory();
  return (
    <div className="container">
      <div className="mt-5 mb-5">
        <img src={logo} className="logo" />
      </div>
      <div className="row pt-5">
        <div className="col-md-8 m-auto text-center">
          <img src={accessdenied} className="img-fluid" />
          <h1 style={{ fontSize: "52px" }} className="mt-4">
            Access Denied
          </h1>
          <p
            className="default-p mt-3"
            style={{ fontSize: "20px", fontWeight: "400" }}
          >
            We are sorry but shipping from USA is only available to USA and
            Bangladesh
          </p>
          <div>
            <button
              className="btn-default btn-notify-curved pl-4 pr-4"
              onClick={() => history.push("/my-cart")}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GlobalUSAShipping;
