import { WebsiteHeader } from "./Layout/Header";
import { Breadcrumb } from "reactstrap";
import { BreadcrumbItem } from "reactstrap";
import { NewsLetter } from "./Layout/NewsLetter";
import { Footer } from "./Layout/Footer";
import { useEffect, useState } from "react";
import Endpoint from "../../../Utils/Endpoint";
import axios from "axios";
function RefundAndReturnPolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    getContect()
  }, []);

  const [content, setContent] = useState('')

  const getContect = async () => {
    try {
      const result = await axios.post(`${Endpoint}/api/admin/getStaticTable`, {
        ContentType: "Refund & Return Policy"
      })

      setContent(result.data.data)

      console.log("==============>", content);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getContect()
  }, [])

  return (
    <>
      <WebsiteHeader />

      <div className="container">
        <>
          <div className="pt-2 pb-0">
            <Breadcrumb listTag="div">
              <BreadcrumbItem
                href="/"
                tag="a"
                className="td-none"
                style={{ color: "#B1B1B1" }}
              >
                Home
              </BreadcrumbItem>
              <BreadcrumbItem
                href="#"
                tag="a"
                className="td-none"
                style={{ color: "#787878" }}
              >
                Refund and return
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </>
        <div className="mt-4">
          <div className="text-center">
            <h2 className="text-default">{content && content.ContentType}</h2>
          </div>
          <div className="container mt-3">
            <p style={{ fontSize: "15px" }}>
              {content && content.Content}
            </p>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}
export default RefundAndReturnPolicy;
